import NwdLocations, { DISCARD_NUMERIC_ID } from '@/router/nwd-router';
import { RouteConfig } from 'vue-router';

// components
import { AppRouteConfig } from './AppRoutes';
import { SystemPermission } from '@/api/api';

import Admin from '@/views/Admin/Admin.vue';
import AmsSyncResultsList from '@/views/Admin/AmsSyncResultsList.vue';
import AdminPrinters from '@/views/Admin/AdminPrinters.vue';
import AdminPrinterClients from '@/views/Admin/AdminPrinterClients.vue';

export const ADMIN_ROUTES: AppRouteConfig[] = [
  {
    path: '/admin',
    redirect: {
      name: NwdLocations.admin.amsSyncResultsList.name
    },
    component: Admin,
    meta: {
      requiresAuth: true,
      allowedPermissions: [SystemPermission.ScanToPickAdmin]
    },
    beforeEnter: (to, from, next) => {
      next();
    },
    children: [
      {
        path: 'sync-results',
        name: NwdLocations.admin.amsSyncResultsList.name,
        component: AmsSyncResultsList
      },
      {
        path: 'printers',
        name: NwdLocations.admin.printers.name,
        component: AdminPrinters
      },
      {
        path: 'printer-clients',
        name: NwdLocations.admin.printerClients.name,
        component: AdminPrinterClients
      }
    ]
  }
];
