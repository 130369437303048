<template>
  <v-container>
    <v-sheet rounded elevation="4" class="pa-0 mt-4">

      <div class="d-flex flex-column px-4 pt-4" style="position: relative;">
        <div class="text-h4 d-flex align-center mr-5 fill-height mb-2 mb-sm-0">
          <span style="font-weight: 300;">Incorrect Lanes</span>
        </div>
        <p class="mt-2">
          These Order Line Items have been reported by pickers as having been in the wrong lane/s.
          Please ensure that the relevant information in AMS has been updated before marking these as corrected.
        </p>

        <v-btn
          class="mt-4 mr-4"
          icon
          color="primary"
          :loading="loading"
          @click="fetchIncorrectLaneReports"
          style="position: absolute; top: 0; right: 0;"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="incorrectLanes"
        :items-per-page="10"
        :loading="loading"
      >
        <template v-slot:item.orderLineItem.order.amsOrderId="{ item }">
          <router-link :to="NwdLocations.clerk.orderDetail.detail(item.orderLineItem.orderId)">
            {{ item.orderLineItem.order.amsOrderId.toFixed(1) }}
          </router-link>
        </template>

        <template v-slot:item.dateAdded="{ item }">
          {{ formatDate(item.dateAdded) }}
        </template>

        <template v-slot:item.dateModified="{ item }">
          {{ formatDate(item.dateModified) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn color="primary" small @click="markAsCorrected(item)">
            Mark as Corrected
          </v-btn>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>

import NwdLocations from '@/router/nwd-router';
import { AmsOrderLineItemsClient } from '@/api/api'; // Adjust the import path as needed
import moment from 'moment';

export default {
  data() {
    return {
      NwdLocations,

      loading: false,
      headers: [
        { text: 'Ams Order #', value: 'orderLineItem.order.amsOrderId' },
        { text: 'Product SKU', value: 'orderLineItem.partNo' },
        { text: 'Original Lane', value: 'originalLane' },
        { text: 'Corrected Lane', value: 'correctedLane' },
        { text: 'Reporter', value: 'systemUser.name' },
        { text: 'Date Reported', value: 'dateAdded' },
        { text: '', value: 'actions', sortable: false },
      ],
      incorrectLanes: [],
      amsOrderLineItemsClient: new AmsOrderLineItemsClient(),
    };
  },
  mounted() {
    this.fetchIncorrectLaneReports();
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY h:mm A');
    },
    async fetchIncorrectLaneReports() {
      this.loading = true;
      try {
        const response = await this.amsOrderLineItemsClient.getIncorrectLaneReports();
        this.incorrectLanes = response;
      } catch (error) {
        console.error('Error fetching incorrect lane reports:', error);
      } finally {
        this.loading = false;
      }
    },
    async markAsCorrected(item) {
      try {
        await this.amsOrderLineItemsClient.markLaneCorrected(item.incorrectLaneReportId);
        this.incorrectLanes = this.incorrectLanes.filter(
          report => report.incorrectLaneReportId !== item.incorrectLaneReportId
        );
      } catch (error) {
        console.error('Error marking lane as corrected:', error);
      }
    },
  },
};
</script>
